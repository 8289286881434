import { AnimatePresence, motion } from 'framer-motion'
import React, { Component, createRef } from 'react'
import './Settings.css'
import { pageTransition, pageTransitionDuration } from '../../Util/Animations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import MDSpinner from 'react-md-spinner'
import { ChromePicker } from 'react-color'
import displayNotification from '../../Util/DisplayNotification'
import handleError from '../../Util/HandleError'
import SettingsStatistics from './SettingsStatistics/SettingsStatistics'
import Switch from '../../Util/Switch/Switch'
import { appearTransition, transitionDuration } from '../../Util/Animations'
import isLicenseExpired from '../../Util/IsLicenseExpired'
import siteSettings from '../../siteSettings.json'

export default class Settings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageDisplayed: 'mainSettings',
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            bugReport: '',
            fetchOutstanding: false,
            tempColor: props.themeColor,
            newProductId: props.selectedProductId,
            originalDarkModeSetting: props.darkMode,
            version: "",
            softSyncOutstanding: false,
            syncTimeValue: "",
        }

        this.settingsInput = createRef(null)
        this.settingsInput2 = createRef(null)
        this.settingsInput3 = createRef(null)
    }

    syncTimeTimer = null

    componentDidMount() {
        document.addEventListener('click', () => {
            if ((document.activeElement === this.settingsInput.current) || (document.activeElement === this.settingsInput2.current) || (document.activeElement === this.settingsInput3.current))
                this.props.disableSpaceBar(true)
            else
                this.props.disableSpaceBar(false)
        })

        document.body.onkeyup = (e) => {
            if (e.key === 'Tab') {
                if ((document.activeElement === this.settingsInput.current) || (document.activeElement === this.settingsInput2.current) || (document.activeElement === this.settingsInput3.current))
                    this.props.disableSpaceBar(true)
                else
                    this.props.disableSpaceBar(false)
            }
        }

        this.syncTimeTimer = setInterval(() => {
            this.updateSyncTime()
        }, 1000)

        this.setState({version: siteSettings.version})
    }

    componentWillUnmount() {
        document.removeEventListener('click', () => {
            if ((document.activeElement === this.settingsInput.current) || (document.activeElement === this.settingsInput2.current) || (document.activeElement === this.settingsInput3.current))
                this.props.disableSpaceBar(true)
            else
                this.props.disableSpaceBar(false)
        })
        document.body.onkeyup = (e) => null
        clearInterval(this.syncTimeTimer)
    }

    changeSettingsPage = (newPage, oldPage = null) => {
        if (oldPage === 'displaySettings') {
            this.confirmThemeChange(false)
            if (this.props.darkMode !== this.state.originalDarkModeSetting) {
                this.props.toggleDarkMode(this.state.originalDarkModeSetting)
            }
        }
        if (newPage === 'displaySettings') {
            this.setState({originalDarkModeSetting: this.props.darkMode})
        }
        this.props.toggleCloseButton(newPage === 'mainSettings')
        this.setState({pageDisplayed: newPage})
    }

    fetchPasswordReset = async (e) => {
        e.preventDefault()

        if (this.state.newPassword !== this.state.confirmNewPassword)
            return handleError('Passwords do not match')

        this.setState({fetchOutstanding: true})
        try {
            let fetchResponse = await fetch(`${siteSettings.apiEndpoint}/changepassword.webapi`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain',
                    'Token': this.props.userData.Token,
                },
                body: JSON.stringify({
                    CustomerId: this.props.userData.CustomerId,
                    oldPassword: this.state.oldPassword,
                    newPassword: this.state.newPassword,
                })
            })

            if (fetchResponse.status === 401) {
                return this.props.displayUnauthenticatedHandler()
            }
    
            if (!fetchResponse.ok) {
                handleError(`${await fetchResponse.text()}`)
            }

            if (isLicenseExpired(this.props.userData.LicenseExpiration, this.props.userData.CurrentTime)) {
                return this.props.displayUnauthenticatedHandler(true)
            }
    
            this.props.toggleCloseButton(true)
            this.setState({fetchOutstanding: false, pageDisplayed: 'mainSettings', newPassword: '', confirmNewPassword: ''})
            displayNotification('Password reset successful')
        } catch(error) {
            this.setState({fetchOutstanding: false})
            displayNotification('No network connection detected. Your password will reset when you go online.')
            this.props.backgroundSync('changePassword', {
                Token: this.props.userData.Token,
                CustomerId: this.props.userData.CustomerId,
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword,
            })
        }
    }

    fetchReportBug = async (e) => {
        e.preventDefault()

        this.setState({fetchOutstanding: true})
        try {
            let fetchResponse = await fetch(`${siteSettings.apiEndpoint}/feedback.webapi`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain',
                    'Token': this.props.userData.Token,
                },
                body: JSON.stringify({
                    CustomerId: this.props.userData.CustomerId,
                    feedback: this.state.bugReport
                })
            })
    
            if (fetchResponse.status === 401) {
                return this.props.displayUnauthenticatedHandler()
            }

            if (!fetchResponse.ok) {
                handleError(`${await fetchResponse.text()}`)
            }

            if (isLicenseExpired(this.props.userData.LicenseExpiration, this.props.userData.CurrentTime)) {
                return this.props.displayUnauthenticatedHandler(true)
            }

            this.props.toggleCloseButton(true)
            this.setState({fetchOutstanding: false, pageDisplayed: 'mainSettings', bugReport: ''})
            displayNotification('We\'ve received your report. We\'ll get back to you soon.')
        } catch(error) {
            this.setState({fetchOutstanding: false})
            displayNotification('No network connection detected. Your bug report will be sent when you go online.')
            this.props.backgroundSync('reportBug', {
                Token: this.props.userData.Token,
                CustomerId: this.props.userData.CustomerId,
                feedback: this.state.bugReport
            })
        }
    }

    formatDate = (rawDateString) => {
        let date = new Date(rawDateString)
        const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        let month = MONTHS[date.getMonth()]
        let day = date.getDate() + 1
        let year = date.getFullYear()
        let hour = date.getHours()
        let minute = date.getMinutes()
        let timeSign = 'AM'

        if (hour === 24) {
            hour -= 12
        } else if (hour >= 12) {
            hour -= 12
            timeSign = 'PM'
        } else if (hour === 0) {
            hour = 12
        }


        return `${month} ${day}, ${year}, ${hour}:${minute >= 10 ? minute : `0${minute}`} ${timeSign}`
    }

    changeThemeColor = (newColor) => {
        let root = document.querySelector(':root')
        root.style.setProperty('--primary-color', newColor.hex)
        this.setState({tempColor: newColor.hex})
    }

    confirmThemeChange = (confirmed, newDarkMode = this.props.darkMode) => {
        if (confirmed) {
            this.setState({fetchOutstanding: true})
            this.props.changeThemeColor(this.state.tempColor, () => {
                this.setState({fetchOutstanding: false, originalDarkModeSetting: this.props.darkMode})
            }, true, newDarkMode)
        }
        else {
            let root = document.querySelector(':root')
            root.style.setProperty('--primary-color', this.props.themeColor)
            this.setState({tempColor: this.props.themeColor})
        }
    }

    resetCardDataWarning = () => {
        let body = 'Are you sure you want to reset your card data? This action cannot be undone.'
        this.props.displayConfirmationHandler('Reset card data?', body, async (userSelection) => {
            if (userSelection)
                await this.confirmResetCardData()
                return
        })
    }

    confirmResetCardData = async () => {

        try {
            let body = JSON.stringify({
                customerId: this.props.userData.CustomerId,
                productId: this.props.selectedProductId
            })
            let resetFetch = await fetch(`${siteSettings.apiEndpoint}/resetdata.webapi`, {
                method: "POST",
                headers: {
                    'Content-Type': 'text/plain',
                    'Token': this.props.userData.Token,
                },
                body
            })

            if (resetFetch.status === 401) {
                return this.props.displayUnauthenticatedHandler()
            }

            if (!resetFetch.ok) {
                return handleError('Failed to reset card data')
            }

            if (isLicenseExpired(this.props.userData.LicenseExpiration, this.props.userData.CurrentTime)) {
                return this.props.displayUnauthenticatedHandler(true)
            }

            await this.props.resetCardData()
        } catch(error) {
            console.log(error)
            return handleError('Failed to reset card data')
        }
    }

    handleChangeProductId = (e) => {
        e.preventDefault()
        this.props.updateProductId(this.state.newProductId)
    }

    toggleDarkMode = (newDarkMode) => {
        this.props.toggleDarkMode(newDarkMode)
        this.confirmThemeChange(true, newDarkMode)
    }

    toggleFavoritesOnly = () => {
        this.props.enableFavoritesOnly(this.props.favoritesOnly === 1 ? 2 : 1)
        if (!this.props.isStudying) {
            this.props.updateStatisticsValues()
        }
    }

    syncWithServerHandler = async () => {
        this.setState({softSyncOutstanding: true})
        await this.props.softSyncHandler()
        this.setState({softSyncOutstanding: false, syncTimeValue: '0 seconds ago'})
    }

    updateSyncTime = () => {
        if (this.props.lastSoftSync !== null) {
            let timeString
            let timeDifference = new Date().getTime() - new Date(this.props.lastSoftSync).getTime()
            let seconds = Math.floor(timeDifference / 1000)
            let minutes = Math.floor(timeDifference / 60000)
            let hours = Math.floor(timeDifference / 3600000)
            if (hours >= 1) {
                timeString = `${hours} hour${hours !== 1 ? 's' : ''}, ${minutes % 60} minute${minutes % 60 !== 1 ? 's' : ''} ago`
            } else if (minutes >= 1) {
                timeString = `${minutes} minute${minutes !== 1 ? 's' : ''} ago`
            } else {
                timeString = `${seconds} second${seconds !== 1 ? 's' : ''} ago`
            }

            this.setState({syncTimeValue: timeString})
        }
    }

    render() {
        return (
            <AnimatePresence exitBeforeEnter>
                {this.state.pageDisplayed === 'mainSettings' ?
                    <motion.div className='settingsPageWrapper' key='mainSettings' initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }}>
                        <div className='settingsPagePadding'>
                            <h2 className='settingsPageHeader settingsUnderlinedHeader'>Settings</h2>
                            <AnimatePresence exitBeforeEnter>
                                {this.props.flashcardArray !== null && this.props.settingsStatisticsData !== null ?
                                    <SettingsStatistics
                                        settingsStatisticsData={this.props.settingsStatisticsData}
                                        color={this.props.themeColor}
                                        key='SettingsStatisticsComponent'
                                        isStudying={this.props.isStudying}
                                        pageAnimations={this.props.pageAnimations}
                                        otherAnimations={this.props.otherAnimations}
                                        displayStatsToggle={this.props.displayStatsToggle}
                                        showOverallStats={this.props.showOverallStats}
                                        updateShowOverallStats={this.props.updateShowOverallStats}
                                    />
                                :
                                    <motion.div className='loginButtonLoaderWrapper' style={{height: '135px'}} initial={this.props.pageAnimations ? appearTransition.initial : false} animate={this.props.pageAnimations ? appearTransition.in : false} exit={this.props.pageAnimations ? appearTransition.out : false} transition={{ duration: transitionDuration }} key='SettingsStatisticsLoader'>
                                        <MDSpinner size={35} singleColor={this.state.tempColor}/>
                                    </motion.div>
                                }
                            </AnimatePresence>
                            <h2 className='settingsStatisticsHeader'>Card Settings</h2>
                            <div className='settingsToggleItemWrapper'>
                                <p className='settingsStatisticsToggleText'>Shuffle</p>
                                <Switch
                                    otherAnimations={this.props.otherAnimations}
                                    onClick={() => this.props.changeShuffle(!this.props.shuffle)}
                                    backgroundColor={this.props.shuffle ? this.props.themeColor : ''}
                                    value={this.props.shuffle}
                                />
                            </div>
                            <div className='settingsToggleItemWrapper'>
                                <p className='settingsStatisticsToggleText'>Favorites Only</p>
                                <Switch
                                    otherAnimations={this.props.otherAnimations}
                                    onClick={() => this.toggleFavoritesOnly()}
                                    backgroundColor={this.props.favoritesOnly === 1 ? this.props.themeColor : ''}
                                    value={this.props.favoritesOnly === 1}
                                />
                            </div>
                            <div className='settingsToggleItemWrapper'>
                                <p className='settingsStatisticsToggleText'>Study Incomplete First</p>
                                <Switch
                                    otherAnimations={this.props.otherAnimations}
                                    onClick={() => this.props.toggleStudyIncomplete()}
                                    backgroundColor={this.props.studyIncomplete ? this.props.themeColor : ''}
                                    value={this.props.studyIncomplete}
                                />
                            </div>
                            <div className='settingsToggleItemWrapper'>
                                <p className='settingsStatisticsToggleText'>Study Incorrect Only</p>
                                <Switch
                                    otherAnimations={this.props.otherAnimations}
                                    onClick={() => this.props.toggleStudyIncorrect()}
                                    backgroundColor={this.props.studyIncorrect ? this.props.themeColor : ''}
                                    value={this.props.studyIncorrect}
                                />
                            </div>
                        </div>
                        <h2 className='settingsPageHeader settingsPageOtherHeader'>Other</h2>
                        <div className='settingsPageOtherWrapper'>
                            <button className='settingsPageOtherOption' onClick={() => this.changeSettingsPage('accountDetails')}>Account Details<span className='settingPageOtherArrow' /></button>
                            <button className='settingsPageOtherOption' onClick={() => this.changeSettingsPage('displaySettings')}>Display Settings<span className='settingPageOtherArrow' /></button>
                            <button className='settingsPageOtherOption' onClick={() => this.changeSettingsPage('reportBug')}>Report a Bug<span className='settingPageOtherArrow' /></button>
                            <button className='settingsPageOtherOption' onClick={() => this.changeSettingsPage('syncWithServer')}>Sync With Server<span className='settingPageOtherArrow' /></button>
                            {/* <button className='settingsPageOtherOption' onClick={() => this.changeSettingsPage('productSettings')}>Product Settings<span className='settingPageOtherArrow' /></button> */}
                        </div>
                        <div className='settingsLogoutWrapper'>
                            {this.props.loadingLogout ?
                                <div className='loginButtonLoaderWrapper'>
                                    <MDSpinner size={35} singleColor={this.state.tempColor}/>
                                </div>
                            :
                                <button className='settingsLogoutButton primaryButton' onClick={this.props.logoutHandler}>Logout</button>
                            }
                        </div>
                    </motion.div>

                : this.state.pageDisplayed === 'displaySettings' ?

                    <motion.div className='settingsPageWrapper' key='displaySettings' initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }}>
                        <div className='settingsTabHeaderWrapper'>
                            <button className='settingsTabHeaderBackButton' onClick={() => this.changeSettingsPage('mainSettings', 'displaySettings')}><FontAwesomeIcon className='settingsTabHeaderBackArrow' icon={solid('angle-left')}/>Settings</button>
                            <h3 className='settingsTabHeaderText'>Display Settings</h3>
                        </div>
                        <div className='settingsPagePadding'>
                            <div className='settingsToggleItemWrapper'>
                                <p className='settingsStatisticsToggleText'>Dark Mode</p>
                                <Switch
                                    otherAnimations={this.props.otherAnimations}
                                    onClick={() => this.toggleDarkMode(!this.props.darkMode)}
                                    backgroundColor={this.props.darkMode ? this.state.tempColor : ''}
                                    value={this.props.darkMode}
                                />
                            </div>
                            <div className='settingsToggleItemWrapper'>
                                <p className='settingsStatisticsToggleText'>Page Animations</p>
                                <Switch
                                    otherAnimations={this.props.otherAnimations}
                                    onClick={this.props.enablePageAnimations}
                                    backgroundColor={this.props.pageAnimations ? this.state.tempColor : ''}
                                    value={this.props.pageAnimations ? true : false}
                                />
                            </div>
                            <div className='settingsToggleItemWrapper'>
                                <p className='settingsStatisticsToggleText'>Other Animations</p>
                                <Switch
                                    otherAnimations={this.props.otherAnimations}
                                    onClick={this.props.enableOtherAnimations}
                                    backgroundColor={this.props.otherAnimations ? this.state.tempColor : ''}
                                    value={this.props.otherAnimations ? true : false}
                                />
                            </div>
                            <h2 className='settingsPageHeader'>Application Color</h2>
                            <div className='settingsColorPickerWrapper'>
                                <ChromePicker 
                                    color={ this.state.tempColor }
                                    onChange={ this.changeThemeColor }
                                />
                            </div>
                            {!this.state.fetchOutstanding ?
                                <div className='loginButtonWrapper'>
                                    <button className='loginButton' style={(this.state.tempColor === this.props.themeColor && this.state.originalDarkModeSetting === this.props.darkMode) ? {opacity: 0.5, pointerEvents: 'none'} : {}} onClick={() => this.confirmThemeChange(false)}>Cancel</button>
                                    <button className='loginButton primaryButton' style={(this.state.tempColor === this.props.themeColor && this.state.originalDarkModeSetting === this.props.darkMode) ? {opacity: 0.5, pointerEvents: 'none'} : {}} onClick={() => this.confirmThemeChange(true)}>Save</button>
                                </div>
                            :
                                <div className='loginButtonLoaderWrapper'>
                                    <MDSpinner size={35} singleColor={this.state.tempColor}/>
                                </div>
                            }
                        </div>
                    </motion.div>
                : this.state.pageDisplayed === 'accountDetails' ?
                    <motion.div className='settingsPageWrapper' key='accountDetails' initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }}>
                        <div className='settingsTabHeaderWrapper'>
                            <button className='settingsTabHeaderBackButton' onClick={() => this.changeSettingsPage('mainSettings')}><FontAwesomeIcon className='settingsTabHeaderBackArrow' icon={solid('angle-left')}/>Settings</button>
                            <h3 className='settingsTabHeaderText'>Account Details</h3>
                        </div>
                        <div className='settingsPagePadding'>
                            <h4 className='settingsPageAccountDetailsTitle'>First Name</h4>
                            <p className='settingsPageAccountDetailsInfo'>{this.props.userData.CustomerFirstName}</p>
                            <h4 className='settingsPageAccountDetailsTitle'>Last Name</h4>
                            <p className='settingsPageAccountDetailsInfo'>{this.props.userData.CustomerLastName}</p>
                            <h4 className='settingsPageAccountDetailsTitle'>Username</h4>
                            <p className='settingsPageAccountDetailsInfo'>{this.props.userData.CustomerUserName}</p>
                            <h4 className='settingsPageAccountDetailsTitle'>Registration Date</h4>
                            <p className='settingsPageAccountDetailsInfo'>{this.formatDate(this.props.userData.DateCustCreated)}</p>
                            <h4 className='settingsPageAccountDetailsTitle'>Expiration Date</h4>
                            <p className='settingsPageAccountDetailsInfo'>{this.formatDate(this.props.userData.LicenseExpiration)}</p>
                            <h4 className='settingsPageAccountDetailsTitle'>Demo</h4>
                            <p className='settingsPageAccountDetailsInfo'>{this.props.userData.Demo ? 'True' : 'False'}</p>
                            <h4 className='settingsPageAccountDetailsTitle'>Version</h4>
                            <p className='settingsPageAccountDetailsInfo'>{this.state.version}</p>
                        </div>
                        <h2 className='settingsPageHeader settingsPageOtherHeader' style={{marginTop: 0}}>Other</h2>
                        <div className='settingsPageOtherWrapper'>
                            <button className='settingsPageOtherOption' onClick={() => this.changeSettingsPage('changePassword')}>Change Password<span className='settingPageOtherArrow' /></button>
                        </div>
                        {!this.props.offline && <button className='settingsResetDataButton' onClick={this.resetCardDataWarning}>Reset Card Data</button>}
                    </motion.div>
                : this.state.pageDisplayed === 'reportBug' ?
                    <motion.form className='settingsPageWrapper' onSubmit={this.fetchReportBug} key='reportBug' initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }}>
                        <div className='settingsTabHeaderWrapper'>
                            <button className='settingsTabHeaderBackButton' type="button" onClick={() => this.changeSettingsPage('mainSettings')}><FontAwesomeIcon className='settingsTabHeaderBackArrow' icon={solid('angle-left')}/>Settings</button>
                            <h3 className='settingsTabHeaderText'>Report a Bug</h3>
                        </div>
                        <label className='loginLabel bugReportLabel'>Please Describe Your Issue</label>
                        <textarea type="text" autoComplete="off" ref={this.settingsInput} className='loginInput bugReportTextArea' required value={this.state.bugReport} onChange={(e) => this.setState({bugReport: e.target.value})}/>
                        {!this.state.fetchOutstanding ?
                            <div className='loginButtonWrapper'>
                                <button className='loginButton' type="button" onClick={() => this.changeSettingsPage('mainSettings')}>Cancel</button>
                                <button className='loginButton primaryButton' type="submit">Submit</button>
                            </div>
                        :
                            <div className='loginButtonLoaderWrapper'>
                                <MDSpinner size={35} singleColor={this.props.themeColor}/>
                            </div>
                        }
                    </motion.form>
                : this.state.pageDisplayed === 'productSettings' ?
                    <motion.form onSubmit={this.handleChangeProductId} className='settingsPageWrapper' key='productSettings' initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }}>
                        {/* <div className='settingsTabHeaderWrapper'>
                            <button className='settingsTabHeaderBackButton' type="button" onClick={() => this.changeSettingsPage('mainSettings')}><FontAwesomeIcon className='settingsTabHeaderBackArrow' icon={solid('angle-left')}/>Settings</button>
                            <h3 className='settingsTabHeaderText'>Product Settings</h3>
                        </div>
                        <label className='loginLabel'>Product Id</label>
                        <input type="text" ref={this.settingsInput} className='loginInput' required value={this.state.newProductId} onChange={(e) => this.setState({newProductId: e.target.value})}/>
                        <div className='loginButtonWrapper'>
                            <button className='loginButton' type="button" onClick={() => this.changeSettingsPage('mainSettings')}>Cancel</button>
                            <button className='loginButton primaryButton' type="submit">Submit</button>
                        </div>
                        <div className='settingsToggleItemWrapper' style={{padding: '10px'}}>
                            <p className='settingsStatisticsToggleText'>Test Spaced Mode</p>
                            <Switch
                                pageAnimations={this.props.pageAnimations}
                                onClick={() => this.props.setSpacedTesting(!this.props.testingSpacedMode)}
                                backgroundColor={this.props.testingSpacedMode ? this.props.themeColor : ''}
                                value={this.props.testingSpacedMode ? true : false}
                            />
                        </div> */}
                    </motion.form>
                :   this.state.pageDisplayed === 'syncWithServer' ?
                    <motion.div className='settingsPageWrapper' key='accountDetails' initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }}>
                        <div className='settingsTabHeaderWrapper'>
                            <button className='settingsTabHeaderBackButton' onClick={() => this.changeSettingsPage('mainSettings')}><FontAwesomeIcon className='settingsTabHeaderBackArrow' icon={solid('angle-left')}/>Settings</button>
                            <h3 className='settingsTabHeaderText'>Sync With Server</h3>
                        </div>
                        <p className='settingsSyncWithServerText'>Syncing your data with the server ensures all of your devices are up to date.</p>
                        {this.props.lastSoftSync !== null && <p className='settingsSyncWithServerSubtext'>Last Synced: {this.state.syncTimeValue}</p>}
                        {!this.state.softSyncOutstanding ?
                            <button className='settingsSyncButton primaryButton' onClick={this.syncWithServerHandler}>Sync With Server</button>
                        :
                            // <MDSpinner size={35} singleColor={this.props.themeColor}/>
                            <div className='loginButtonLoaderWrapper'>
                                <MDSpinner size={35} singleColor={this.props.themeColor}/>
                            </div>
                        }
                    </motion.div>
                :
                    <motion.div className='settingsPageWrapper' key='changePassword' initial={this.props.pageAnimations ? pageTransition.initial : false} animate={this.props.pageAnimations ? pageTransition.in : false} exit={this.props.pageAnimations ? pageTransition.out : false} transition={{ duration: pageTransitionDuration }}>
                        <div className='settingsTabHeaderWrapper'>
                            <button className='settingsTabHeaderBackButton' onClick={() => this.changeSettingsPage('accountDetails')}><FontAwesomeIcon className='settingsTabHeaderBackArrow' icon={solid('angle-left')}/>Account</button>
                            <h3 className='settingsTabHeaderText'>Change Password</h3>
                        </div>
                        {!this.props.offline ?
                            <form className='' onSubmit={this.fetchPasswordReset}>
                                <input type="hidden" autoComplete="username" className='loginInput' required value={this.props.userData.email} onChange={(e) => this.setState({newPassword: e.target.value})}/>
                                <label className='loginLabel'>Current Password</label>
                                <input type="password" autoComplete="password" ref={this.settingsInput} className='loginInput' required value={this.state.oldPassword} onChange={(e) => this.setState({oldPassword: e.target.value})}/>
                                <label className='loginLabel'>New Password</label>
                                <input type="password" autoComplete="newPassword" ref={this.settingsInput2} className='loginInput' required value={this.state.newPassword} onChange={(e) => this.setState({newPassword: e.target.value})}/>
                                <label className='loginLabel'>Confirm Password</label>
                                <input type="password" autoComplete="confirmNewPassword" ref={this.settingsInput3} className='loginInput' required value={this.state.confirmNewPassword} onChange={(e) => this.setState({confirmNewPassword: e.target.value})}/>
                                {!this.state.fetchOutstanding ?
                                    <div className='loginButtonWrapper'>
                                        <button className='loginButton' type="button" onClick={() => this.changeSettingsPage('accountDetails')}>Cancel</button>
                                        <button className='loginButton primaryButton' type="submit">Submit</button>
                                    </div>
                                :
                                    <div className='loginButtonLoaderWrapper'>
                                        <MDSpinner size={35} singleColor={this.props.themeColor}/>
                                    </div>
                                }
                            </form>
                        :
                            <p className='offlineText'>Unable to change password while offline. Please try again once you are connected to a network.</p>
                        }
                    </motion.div>
                }
            </AnimatePresence>
        )
    }
}
