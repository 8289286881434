import React, { Component, useCallback } from 'react'
import './App.css';
import Settings from './Components/Settings/Settings';
import FlashcardPage from './Pages/FlashcardPage/FlashcardPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AnimatePresence, motion } from 'framer-motion';
import { settingsDrawerTransition, backdropTransition, transitionDuration, backdropTransitionDuration } from './Util/Animations'
import LoginPage from './Pages/LoginPage/LoginPage';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UnauthenticatedPopup from './Components/UnauthenticatedPopup/UnauthenticatedPopup';
import handleError from './Util/HandleError';
import ConfirmationPopup from './Components/ConfirmationPopup/ConfirmationPopup';
// import Logo from './Assets/Mark.svg'
import { ReactComponent as Logo } from './Assets/Logo.svg';
import displayNotification from './Util/DisplayNotification';
import CategoriesPage from './Pages/CategoriesPage/CategoriesPage';
import SpacedModeSettings from './Pages/SpacedModeSettings/SpacedModeSettings';
import SpacedModePopup from './Components/SpacedModePopup/SpacedModePopup';
import isLicenseExpired from './Util/IsLicenseExpired';
import siteSettings from './siteSettings.json'

class App extends Component {
  constructor() {
    super()
    this.state = {
      currentPage: 'Categories',
      imageUrl: null,
      displayImage: false,
      windowHeight: window.innerHeight,
      displayMobileSettings: false,
      darkMode: false,
      mode: 'linear',
      settingsCallback: () => null,
      flipAnimation: 1,
      userData: null,
      favoritesOnly: 2,
      showSettingsCloseButton: true,
      // themeColor: '#0db6c5',
      themeColor: siteSettings.BaseThemeColor,
      favoriteTotal: 0,
      cardsCorrect: 0,
      cardsIncorrect: 0,
      cardsUnanswered: 0,
      spaceBarDisabled: false,
      displayUnauthenticated: false,
      themeChangeOutstanding: false,
      offline: false,
      displayConfirmationPopup: false,
      confirmationData: {
        header: "",
        body: "",
        callback: () => null,
        enableSecondaryButton: true,
        primaryButtonText: null,
        secondaryButtonText: null
      },
      userSyncData: undefined,
      selectedProductId: siteSettings.productId,
      isSyncDataSynced: false,
      shuffle: false,
      flashcards: null,
      flashcardArray: null,
      spacedMode: false,
      initialReviewPeriod: 0,
      reviewMultiplier: 0,
      downloadSize: 0,
      downloadProgress: 0,
      displaySpacedModePopup: 0,
      spacedModeCorrect: 0,
      spacedModeIncorrect: 0,
      spacedModeNextReviewDate: "",
      testingSpacedMode: false,
      spacedModeConfirmationCallback: null,
      displaySecondSpacedModePopup: true,
      isStudying: false,
      loadingCards: false,
      otherAnimations: true,
      pageAnimations: true,
      licenseExpired: false,
      studyIncomplete: true,
      flashcardOtherAnimationsCallback: () => null,
      settingsStatisticsData: null,
      flashcardKeyArray: [],
      displayStatsToggle: false,
      showOverallStats: true,
      loadingLogout: false,
      touchEndCallback: null,
      cardWasClicked: false,
      studyIncorrect: false,
      cardsToSync: [],
      lastSoftSync: null,
    }

    this.imgRef = React.createRef();
  }

  async componentDidMount() {
    if ('serviceWorker' in navigator && this.state.userData === null) {
      let loginCacheMatch = await caches.match(`${siteSettings.apiEndpoint}/login.webapi`)

      if (loginCacheMatch !== undefined) {
        let body = await loginCacheMatch.text()
        this.updateUserData(JSON.parse(body))
      }
    }
    // console.log(siteSettings)

    window.addEventListener("scroll", (e) => {
      e.preventDefault();
      window.scrollTo(0, 0);
    });

    window.addEventListener('resize', () => {
      this.setState({windowHeight: window.innerHeight})
    })
    let root = document.querySelector(':root')
    root.style.setProperty('--primary-color', this.state.themeColor)

    this.registerServiceWorker()

    window.addEventListener('offline', this.offlineHandler)
    window.addEventListener('online', this.onlineHandler)
  }

  onlineHandler = (e) => {
    console.log('Now Online')
    this.setState({offline: false})
  }

  offlineHandler = (e) => {
    console.log('Now Offline')
    this.setState({offline: true})
  }

  componentWillUnmount() {
    window.removeEventListener('offline', this.offlineHandler)
    window.removeEventListener('online', this.onlineHandler)
    navigator.serviceWorker.removeEventListener('message', this.handleServiceWorkerMessage)
    window.removeEventListener('load', () => {
      navigator.serviceWorker.register('./ServiceWorker.js')
      .then(() => console.log('Service Worker: Registered'))
      .catch(() => console.log('Service Worker: Failed to Register'))
    })
    window.removeEventListener("scroll", (e) => {
      e.preventDefault();
      window.scrollTo(0, 0);
    });
  }

  toggleDarkMode = async (darkMode) => {
    let root = document.querySelector(':root')
    if (!darkMode && darkMode !== this.state.darkMode) {
      // Light Mode
      root.style.setProperty('--primary-background-color', '#EDEDED')
      root.style.setProperty('--secondary-background-color', '#ffffff')
      root.style.setProperty('--text-color', 'black')
      root.style.setProperty('--secondary-text-color', '#222222')
      var metaThemeColor = document.querySelector("meta[name=theme-color]")
      metaThemeColor.setAttribute("content", '#ffffff')
    } else if (darkMode !== this.state.darkMode) {
      // Dark Mode
      root.style.setProperty('--primary-background-color', '#2b2b2b')
      root.style.setProperty('--secondary-background-color', '#3b3b3b')
      root.style.setProperty('--text-color', 'white')
      root.style.setProperty('--secondary-text-color', '#fbfbfb')
      var metaThemeColor = document.querySelector("meta[name=theme-color]")
      metaThemeColor.setAttribute("content", '#3b3b3b')
    }
    this.setState({darkMode})
  }

  fetchThemeChange = async (darkMode, newThemeColor) => {
    await this.updateThemeCache(darkMode, newThemeColor)
    let darkmodeRequest = new Request(`${siteSettings.apiEndpoint}/toggledarkmode.webapi`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Token': this.state.userData.Token,
      },
      body: JSON.stringify({
        CustomerId: this.state.userData.CustomerId,
        DarkMode: darkMode,
        ThemeColor: newThemeColor
      })
    })
    try {
      let themeFetch = await fetch(darkmodeRequest)

      if (themeFetch.status === 401) {
        this.displayUnauthenticatedHandler()
        return null
      }

      if (!themeFetch.ok) {
        handleError('Failed to save theme change. Local changes still applied.')
        return null
      }

      if (isLicenseExpired(this.state.userData.LicenseExpiration, this.state.userData.CurrentTime)) {
        return this.displayUnauthenticatedHandler(true)
      }
  
      return themeFetch
    } catch(error) {
      console.log(error)
      let darkmodeData = {
        token: this.state.userData.Token,
        CustomerId: this.state.userData.CustomerId,
        DarkMode: darkMode,
        ThemeColor: newThemeColor
      }
      this.backgroundSync('saveTheme', darkmodeData)
    }
  }

  changeThemeColor = async (newColor, callback = () => null, enableFetch = true, newDarkMode = this.state.darkMode) => {
    if (enableFetch)
      await this.fetchThemeChange(newDarkMode, newColor)
    this.setState({themeColor: newColor}, () => {
      if (enableFetch)
        callback()
    })
  }

  registerServiceWorker = () => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('./ServiceWorker.js')
        .then(() => {
          console.log('Service Worker: Registered')
        })
        .catch(() => console.log('Service Worker: Failed to Register'))
      })
      navigator.serviceWorker.addEventListener('message', this.handleServiceWorkerMessage)
    }
  }

  handleServiceWorkerMessage = async ({data}) => {
    console.log('Receiving SW Message')
    switch(data.tag) {
      case 'getCards':
        break
      case 'softSync':
        let softSyncResponse = JSON.parse(data.data.responseText)
        if (softSyncResponse.NeedsHardSync) {
          this.hardSyncHandler()
        }
        break
      case 'hardSync':
        break
      case 'saveTheme':
        displayNotification('Theme successfully synced with server')
        // No need to do anything here
        break
      case 'changePassword':
        break
      case 'reportBug':
        displayNotification('Bug report sent successfully')
        break
      case 'Failed':
        handleError(data.data)
        break
      case 'Unauthenticated':
        this.displayUnauthenticatedHandler()
        break
      default:
        console.log(`No message handler for tag "${data.tag}"`)
    }
  }

  updateThemeCache = async (darkMode, themeColor) => {
    let loginCache = await caches.match(`${siteSettings.apiEndpoint}/login.webapi`)
    if (loginCache) {
      let responseBody = JSON.parse(await loginCache.text())
      responseBody.DarkMode = darkMode
      responseBody.ThemeColor = themeColor
      let responseObject = new Response(JSON.stringify(responseBody))
      let openCache = await caches.open('Login')
      openCache.put(`${siteSettings.apiEndpoint}/login.webapi`, responseObject.clone())
    }
  }

  backgroundSync = (tag, data) => {
    navigator.serviceWorker.ready
    .then((swRegistration) => {
      navigator.serviceWorker.controller.postMessage(JSON.stringify({
        key: tag,
        data,
        siteSettings
      }))
      swRegistration.sync.register(tag)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  changeMode = (newMode) => {
    if (this.state.mode !== newMode) {
      this.setState({mode: newMode}, () => {
        if (this.state.settingsCallback !== null)
          this.state.settingsCallback()
      })
    }
  }

  enableFlipAnimation = (enable) => {
    this.setState({flipAnimation: enable})
  }

  logoutHandler = async (verify = true, softSync = true) => {
    if (verify && this.state.offline) {
      let body = 'You aren\'t connected to the internet. If you log out now, you won\'t be able to log in until you\'re online. Are you sure?'
      
      return this.displayConfirmationHandler('Logout?', body, (userDecision) => {
        if (userDecision)
          this.logoutHandler(false)
      })
    }

    if (softSync) {
      this.setState({loadingLogout: true})
      await this.softSyncHandler()
      this.setState({loadingLogout: false})
    }

    await caches.delete('Login')
    await caches.delete('HardSync')

    this.setState({
      userData: null,
      displayMobileSettings: false,
      currentPage: 'Categories',
      settingsCallback: () => null,
      displayUnauthenticated: false,
      darkMode: false,
      themeColor: '#8A244B',
      userSyncData: undefined,
      shuffle: false,
      flashcards: null,
      flashcardArray: null,
      spacedMode: false,
      flashcardOtherAnimationsCallback: () => null,
    }, () => {
      let root = document.querySelector(':root')
      root.style.setProperty('--primary-background-color', '#EDEDED')
      root.style.setProperty('--secondary-background-color', '#ffffff')
      root.style.setProperty('--text-color', 'black')
      root.style.setProperty('--primary-color', '#8A244B')
      root.style.setProperty('--secondary-text-color', '#222222')
    })
  }

  onImageZoomUpdate = ({ x, y, scale }) => {
    const { current: img } = this.imgRef

    if (img) {
      const value = make3dTransformValue({ x, y, scale })

      img.style.setProperty("transform", value)
    }
  }

  enableFavoritesOnly = (newSelection) => {
    this.setState({favoritesOnly: newSelection})
    if (this.state.settingsCallback !== null)
      setTimeout(() => {
        this.state.settingsCallback()
      }, 10)
  }

  updateUserData = (userData) => {
    this.toggleDarkMode(userData.DarkMode)
    this.changeThemeColor(userData.ThemeColor, null, false)
    let root = document.querySelector(':root')
    root.style.setProperty('--primary-color', userData.ThemeColor)

    let initialReviewPeriod = userData.InitialReviewPeriod.length === 0 ? 1 : Number(userData.InitialReviewPeriod)
    let reviewMultiplier = userData.ReviewMultiplier.length === 0 ? 2 : Number(userData.ReviewMultiplier)

    this.setState({userData, initialReviewPeriod, reviewMultiplier})
  }

  updateLoginCache = async (userData) => {
    let loginCache = await caches.match(`${siteSettings.apiEndpoint}/login.webapi`)
    if (loginCache) {
      let responseObject = new Response(JSON.stringify(userData))
      let openCache = await caches.open('Login')
      openCache.put(`${siteSettings.apiEndpoint}/login.webapi`, responseObject.clone())
    }
  }

  updateCardsCorrect = (cardScores) => {
    let userData = {...this.state.userData}
    userData.TotalNumberOfQuestions = cardScores.totalCards
    userData.TotalNumberOfIncorrect = cardScores.cardsIncorrect
    userData.IncompletedNumberOfQuestions = cardScores.totalCards - cardScores.cardsCorrect - cardScores.cardsIncorrect
    userData.CompletedNumberOfQuestions = cardScores.cardsCorrect + cardScores.cardsIncorrect
    this.setState({userData})
  }

  disableSpaceBar = (active) => {
    this.setState({spaceBarDisabled: active})
  }

  displayUnauthenticatedHandler = async (licenseExpired = false, softsync = true) => {
    if (licenseExpired) {
      this.setState({displayUnauthenticated: true, licenseExpired: true})
      if (softsync) {
        await this.softSyncHandler(null, false)
      }
      await caches.delete('GetCards')
      await caches.delete('HardSync')
      await caches.delete('AppData')
    } else {
      this.setState({displayUnauthenticated: true})
      if (softsync) {
        await this.softSyncHandler(null, false)
      }
    }
  }

  hardSyncHandler = async ( overrideSync = false ) => {
    let productId = this.state.selectedProductId
    if (this.state.isSyncDataSynced && !overrideSync) {
      return
    }

    let fetchBody = {
      productId,
      customerId: this.state.userData.CustomerId,
      LastHardSync: this.state.userSyncData ? this.state.userSyncData.LastHardSync : null
    }

    try {
      let syncFetch = await fetch(`${siteSettings.apiEndpoint}/hardsync.webapi`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
          'Token': this.state.userData.Token
        },
        body: JSON.stringify(fetchBody)
      })

      if (syncFetch.status === 401) {
        return this.displayUnauthenticatedHandler()
      }

      if (!syncFetch.ok)
        return handleError('Failed to sync card scores')

      if (isLicenseExpired(this.state.userData.LicenseExpiration, this.state.userData.CurrentTime)) {
        return this.displayUnauthenticatedHandler(true)
      }

      let fetchData = await syncFetch.text()

      let serverText
      if (fetchData.includes("\"cd\""))
        return this.setState({userSyncData: JSON.parse(fetchData), isSyncDataSynced: true})

      if (!fetchData.includes('Server Json is Empty. There is no data to send.')) {
        fetchData = JSON.parse(fetchData)
        if (fetchData.serverjson.length > 0) {
          serverText = JSON.parse(fetchData.serverjson)
        } else {
          serverText = false
        }
      } else {
        serverText = false
      }

      let userSyncData = {...this.state.userSyncData}
      userSyncData = {
        cd: serverText ? serverText : [],
        productId,
        customerId: this.state.userData.CustomerId,
        LastHardSync: fetchData.LastHardSync
      }

      let favoriteTotal = 0
      let cardsCorrect = 0
      let cardsIncorrect = 0
      let cardsUnanswered = 0

      userSyncData.cd.forEach((value, index) => {
        if (value.favorited) {
          favoriteTotal++
        }
        if (value.LastMarkedCorrect) {
          cardsCorrect++
        }
        if (value.LastMarkedIncorrect) {
          cardsIncorrect++
        }
      })

      cardsUnanswered = this.state.flashcardArray.length - cardsCorrect - cardsIncorrect

      this.setState({
        userSyncData,
        displayedSyncData: userSyncData,
        isSyncDataSynced: true,
        favoriteTotal,
        cardsCorrect,
        cardsIncorrect,
        cardsUnanswered
      }, () => {
        this.updateStatisticsValues()
      })
      let responseObject = new Response(JSON.stringify(userSyncData))
      let openCache = await caches.open('HardSync')
      openCache.put(`${siteSettings.apiEndpoint}/hardsync.webapi`, responseObject.clone())
  
    } catch(error) {
      if (this.state.offline) {
        handleError('Unable to get card data. Card data will sync when you go online')
      } else {
        handleError(`${error}`)
      }
    }
  }

  softSyncHandler = async (callback = null, hardSync = true) => {
    // console.log('called soft sync handler', this.state.isSyncDataSynced, hardSync)
    // let time = new Date()
    // let formattedTime = {
    //   hour: time.getHours(),
    //   minutes: time.getMinutes(),
    //   seconds: time.getSeconds()
    // }
    // displayNotification(`${formattedTime.hour}:${formattedTime.minutes}:${formattedTime.seconds}`)
    if (this.state.isSyncDataSynced && !hardSync){
      return
    }

    let productId = this.state.selectedProductId
    let fetchBody = {
      cd: this.state.cardsToSync,
      productId,
      customerId: this.state.userData.CustomerId,
      LastHardSync: this.state.userSyncData ? this.state.userSyncData.LastHardSync : null,
      VersionNumber: this.state.cardVersion
    }

    // console.log(fetchBody)

    try {
      let syncFetch = await fetch(`${siteSettings.apiEndpoint}/softsync.webapi`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
          'Token': this.state.userData.Token,
        },
        body: JSON.stringify(fetchBody)
      })

      if (syncFetch.status === 401) {
        return this.displayUnauthenticatedHandler(false, false)
      }
  
      if (!syncFetch.ok)
        return handleError('Failed to sync card scores')

      if (isLicenseExpired(this.state.userData.LicenseExpiration, this.state.userData.CurrentTime)) {
        return this.displayUnauthenticatedHandler(true)
      }

      this.setState({cardsToSync: [], lastSoftSync: new Date()})

      let response = await syncFetch.text()
      let fetchData = JSON.parse(response)

      if (fetchData.NeedsHardSync && hardSync)
        this.hardSyncHandler()
      if (fetchData.NeedsCardUpdate && hardSync) {
        if (callback !== null) {
          this.getCards().then(() => {
            callback()
          })
        } else {
          this.getCards()
        }
      }
    } catch(error) {
      this.backgroundSync('softSync', {
        Token: this.state.userData.Token,
        fetchBody
      })
      console.log(error)
    }
  }

  verifyLoginStatus = () => {
    let currentTime = new Date
    let loginTime = new Date(this.state.userData.CurrentTime)

    let daysDifference = (currentTime - loginTime) / 1000 / 60 / 60 / 24

    if (daysDifference >= 2)
      return this.displayUnauthenticatedHandler()

    return
  }

  updateSyncData = async (syncData, productId) => {
    // console.log(syncData)
    if (this.state.isSyncDataSynced) {
      this.setState({isSyncDataSynced: false})
    }
    let userSyncData = {...this.state.userSyncData}
    let cardsCorrect = this.state.cardsCorrect
    let cardsIncorrect = this.state.cardsIncorrect
    let cardsUnanswered = this.state.cardsUnanswered

    if (!userSyncData) {
      return handleError('Unable to update sync data')
    }
    let foundIndex = -1
    if (userSyncData.cd) {
      for(let i = 0; i < userSyncData.cd.length; ++i) {
        if (userSyncData.cd[i].cardGeneratedID === syncData.cardGeneratedID)
          foundIndex = i
      }
    } else {
      foundIndex = -2
    }

    // If card isn't found (foundIndex === -1) or userSyncData doesn't exist (foundIndex === -2)
    if (foundIndex === -1 || foundIndex === -2) {
      let newCardData = {
        cardGeneratedID: syncData.cardGeneratedID,
        cc: [syncData],
        customerId: this.state.userData.CustomerId,
        LastMarkedCorrect: syncData.correct,
        LastMarkedIncorrect: !syncData.correct,
        LastSync: null,
        NextReviewDate: null,
        NumAttempts: 1,
        SpacedModeStreak: (this.state.spacedMode && syncData.correct) ? 1 : 0,
        NumTimesCorrect: syncData.correct ? 1 : 0,
        NumTimesIncorrect: !syncData.correct ? 1 : 0,
        LastFavorited: null,
        favorited: false
      }

      if (syncData.correct === true) {
        cardsCorrect++
      } else if (syncData.incorrect === true) {
        cardsIncorrect++
      }

      cardsUnanswered--

      if (foundIndex === -1) {
        userSyncData.cd.push(newCardData)
        foundIndex = userSyncData.cd.length - 1
      } else {
        userSyncData.cd = [newCardData]
        foundIndex = 0
      }

      this.setState({cardsToSync: [...this.state.cardsToSync, newCardData]})
    }
    // Else card was found at foundIndex
    else {
      if (userSyncData.cd[foundIndex].LastMarkedCorrect && syncData.incorrect) {
        cardsCorrect--
        cardsIncorrect++
        userSyncData.cd[foundIndex].NumTimesIncorrect++
      } else if (userSyncData.cd[foundIndex].LastMarkedIncorrect && syncData.correct) {
        cardsIncorrect--
        cardsCorrect++
        userSyncData.cd[foundIndex].NumTimesCorrect++
      }

      userSyncData.cd[foundIndex].cc.push(syncData)
      userSyncData.cd[foundIndex].LastMarkedCorrect = syncData.correct
      userSyncData.cd[foundIndex].LastMarkedIncorrect = syncData.incorrect
      userSyncData.cd[foundIndex].NumAttempts++

      if (userSyncData.cd[foundIndex].NumAttempts === 1) {
        cardsUnanswered--
      }

      this.setState({cardsToSync: [...this.state.cardsToSync, userSyncData.cd[foundIndex]]})
    }

    if (this.state.spacedMode) {
      let date = new Date();
      let reviewMultiplier

      // Check if the syncData has no previous spaced mode entries
      if (userSyncData.cd[foundIndex].NextReviewDate === undefined ||
          userSyncData.cd[foundIndex].NextReviewDate === null)
      {
        reviewMultiplier = 1
      }

      // Else entry was found
      else {
        reviewMultiplier = Math.pow(Number(this.state.reviewMultiplier) * Number(this.state.initialReviewPeriod), ++userSyncData.cd[foundIndex].SpacedModeStreak)
      }

      if (!syncData.correct) {
        userSyncData.cd[foundIndex].SpacedModeStreak = 0
      }

      date.setDate(date.getDate() + reviewMultiplier);
      userSyncData.cd[foundIndex].NextReviewDate = `${date}`
    }

    userSyncData = {
      cd: userSyncData.cd,
      productId,
      customerId: this.state.userData.CustomerId,
      LastHardSync: userSyncData.LastHardSync
    }

    this.setState({cardsCorrect, cardsIncorrect, cardsUnanswered})
    this.updateUserSyncData(userSyncData)
  }

  updateStatisticsValues = () => {
    let settingsStatisticsData = {}

    if (this.state.isStudying) {
      settingsStatisticsData = {
        ...this.getOverallStatisticsData(),
        ...this.getCurrentStatisticsData()
      }
    } else {
      settingsStatisticsData = {
        ...this.getOverallStatisticsData(),
        currentCorrect: 0,
        currentIncorrect: 0,
        currentUnanswered: 0,
        currentFavorited: 0
      }
    }

    let displayStatsToggle = false

    if (settingsStatisticsData.currentUnanswered !== settingsStatisticsData.overallUnanswered && this.state.isStudying) {
      displayStatsToggle = true
    }

    this.setState({
      settingsStatisticsData,
      displayStatsToggle
    })
  }

  getOverallStatisticsData = () => {
    let overallCorrect = 0
    let overallIncorrect = 0
    let overallUnanswered = 0
    let overallFavorited = 0

    this.state.userSyncData.cd.forEach((syncValue, syncIndex) => {
      if (syncValue.LastMarkedCorrect)
        overallCorrect++
      else if (syncValue.LastMarkedIncorrect)
        overallIncorrect++
      
      if (syncValue.favorited)
        overallFavorited++
    })

    overallUnanswered = this.state.flashcardArray.length - overallCorrect - overallIncorrect

    return {
      overallCorrect,
      overallIncorrect,
      overallUnanswered,
      overallFavorited
    }
  }

  getCurrentStatisticsData = () => {
    let currentCorrect = 0
    let currentIncorrect = 0
    let currentUnanswered = 0
    let currentFavorited = 0

    let relevantUserSyncData = []

    this.state.userSyncData.cd.forEach((syncValue, syncIndex) => {
      if (this.state.flashcardKeyArray.some((key) => key == syncValue.cardGeneratedID)) {
        relevantUserSyncData.push(syncValue)
      }
    })

    relevantUserSyncData.forEach((syncValue, syncIndex) => {
      if (syncValue.LastMarkedCorrect)
        currentCorrect++
      else if (syncValue.LastMarkedIncorrect)
        currentIncorrect++

      if (syncValue.favorited)
        currentFavorited++
    })

    currentUnanswered = this.state.flashcardKeyArray.length - currentCorrect - currentIncorrect
    
    return {
      currentCorrect,
      currentIncorrect,
      currentUnanswered,
      currentFavorited
    }
  }

  favoriteQuestionHandler = (cardId) => {
    if (this.state.isSyncDataSynced) {
      this.setState({isSyncDataSynced: false})
    }

    let userSyncData = {...this.state.userSyncData}

    let foundIndex = -1

    if (userSyncData.cd) {
      for(let i = 0; i < userSyncData.cd.length; ++i) {
        if (userSyncData.cd[i].cardGeneratedID == cardId) {
          foundIndex = i
        }
      }
    }

    let favoriteTotal

    if (foundIndex === -1) {
      let newCard = {
        cardGeneratedID: `${cardId}`,
        cc: [],
        customerId: this.state.userData.CustomerId,
        LastMarkedCorrect: null,
        LastMarkedIncorrect: null,
        LastSync: null,
        NextReviewDate: null,
        NumAttempts: 0,
        NumTimesCorrect: 0,
        NumTimesIncorrect: 0,
        LastFavorited: `${(new Date).toJSON()}`,
        favorited: true
      }
      userSyncData.cd.push(newCard)
      favoriteTotal = this.state.favoriteTotal + 1
    } else {
      userSyncData.cd[foundIndex].favorited = !userSyncData.cd[foundIndex].favorited
      userSyncData.cd[foundIndex].LastFavorited = `${(new Date).toJSON()}`
      favoriteTotal = this.state.favoriteTotal + (userSyncData.cd[foundIndex].favorited ? 1 : -1)
    }

    this.setState({favoriteTotal})
    this.updateUserSyncData(userSyncData)
    this.updateStatisticsValues()
  }

  updateUserSyncData = async (userSyncData) => {
    let displayedSyncData = {...userSyncData}
    displayedSyncData.cd = []
    userSyncData.cd.forEach((cdValue, cdIndex) => {
      if (this.state.flashcards[cdValue.cardGeneratedID]) {
        displayedSyncData.cd.push(cdValue)
      }
   })

    this.setState({userSyncData, displayedSyncData})
    try {
      let responseObject = new Response(JSON.stringify(userSyncData))
      let openCache = await caches.open('HardSync')
      openCache.put(`${siteSettings.apiEndpoint}/hardsync.webapi`, responseObject.clone())
    } catch(error) {
      console.log(error)
    }
  }

  changePageHandler = async (newPage) => {
    let oldPage = this.state.currentPage
    this.setState({
      currentPage: newPage,
      settingsCallback: null,
      isSyncDataSynced: false,
      isStudying: newPage === 'Flashcard' ? true : false,
      flashcardOtherAnimationsCallback: () => null,
    })
    if (oldPage === 'Flashcard') {
      this.setState({displayStatsToggle: false, showOverallStats: true})
      await this.softSyncHandler()
      this.updateStatisticsValues()
      clearInterval(this.softSyncInterval)
    }
  }

  displayConfirmationHandler = (header, body, callback, enableSecondaryButton = true, primaryButtonText = null, secondaryButtonText = null) => {
    let confirmationData = {
      header,
      body,
      callback,
      enableSecondaryButton,
      primaryButtonText,
      secondaryButtonText
    }
    this.setState({displayConfirmationPopup: true, confirmationData})
  }

  handleConfirmationPopupClick = (userSelection) => {
    this.state.confirmationData.callback(userSelection)
    this.setState({displayConfirmationPopup: false})
  }

  resetCardData = async () => {
    this.setState({
      userSyncData: null,
      cardsCorrect: 0,
      favoriteTotal: 0,
      cardsIncorrect: 0,
      cardsUnanswered: 0
    })

    displayNotification('Card data reset successfully')

    await caches.delete('HardSync')

    this.hardSyncHandler(true)
  }

  startStudyingHandler = (categories) => {
    let selectedCategoryTitles = []
    
    categories.forEach((value) => {
      if (value.selected) {
        selectedCategoryTitles.push(value.title)
      }
    })

    if (selectedCategoryTitles.length === 0)
      return displayNotification('Select a category to study')
    
    let flashcards = {}
    let displayedSyncData = {...this.state.userSyncData}
    // let displayedSyncData = JSON.parse(JSON.stringify(this.state.userSyncData))
    displayedSyncData.cd = []

    this.state.flashcardArray.forEach((value, index) => {
      if (selectedCategoryTitles.some(title => title === value.cf.CategoryName)) {
        for (let i = 0; i < this.state.userSyncData.cd.length; ++i) {
          if (this.state.userSyncData.cd[i].cardGeneratedID == value.cf.CardGeneratedID) {
            displayedSyncData.cd.push(this.state.userSyncData.cd[i])
            break
          }
        }
        flashcards[value.cf.CardGeneratedID] = value.cf
      }
    })

    this.setState({flashcards, displayedSyncData}, () => {
      this.changePageHandler('Flashcard')
    })
  }

  getCards = async () => {
    if (!this.state.loadingCards) {
      this.setState({loadingCards: true})
      await fetch(`${siteSettings.apiEndpoint}/getcards.webapi`, {
        method: 'GET',
        headers: {
          'Token': this.state.userData.Token,
          'ProductId': this.state.selectedProductId
        },
      })
      .then((response) => {
        // console.log('Call to getcards was successful')
        if (response.status === 401) {
          throw this.displayUnauthenticatedHandler()
        } else if (isLicenseExpired(this.state.userData.LicenseExpiration, this.state.userData.CurrentTime)) {
          return this.displayUnauthenticatedHandler(true)
        } else {
          const decoder = new TextDecoder('utf-8')
          let result = ''
          let reader = response.body.getReader()
          const contentLength = response.headers.get('bytes')
          let downloadProgress = 0;
          // console.log('contentLength', contentLength)
          this.setState({downloadSize: Number(contentLength), cardVersion: response.headers.get('version')}, () => {
            let processText
            reader
            .read()
            .then(processText = ({ done, value }) => {
              if (done) {
                return result
              }
              downloadProgress += value.byteLength
              this.setState({downloadProgress})
              result += decoder.decode(value);
            
              // Read some more, and call this function again
              return reader.read().then(processText);
            })
            .then(async (parsedResponse) => {

              this.setState({loadingCards: false})
              if (parsedResponse.includes('The productId does not match what you purchased.')) {
                // Delete cached response
                let cacheMatch = await caches.match(`${siteSettings.apiEndpoint}/getcards.webapi`)
                if (cacheMatch !== undefined) {
                  await caches.delete('GetCards')
                }
                throw new Error('The productId does not match what you purchased.')
              }
              let responseArray = JSON.parse(parsedResponse)
              this.setState({flashcardArray: responseArray}, () => {
                this.hardSyncHandler(true)
              })
            })
            .catch((error) => {
              this.setState({loadingCards: false})
              let errorString = `${error}`
              console.log(error)
              if (errorString.includes('Load failed')) {
                displayNotification('Download failed. Restarting download...')
                this.getCards()
              } else if (!errorString.includes('Unauthenticated')) {
                handleError('Failed to get cards')
              }
            })
          })
      }})
    }
  }

  changeShuffle = (settingValue) => {
    this.setState({shuffle: settingValue}, () => {
      if (this.state.currentPage === 'Flashcard') {
        this.state.settingsCallback()
      }
    })
  }

  updateProductId = async (newProductId) => {
    newProductId = Number(newProductId)
    if (isNaN(newProductId)) {
      return handleError('Product Id Must be a number')
    }
    if (newProductId === this.state.selectedProductId) {
      displayNotification(`Product Id is already ${newProductId}`)
    } else {

      //Delete caches
      await caches.delete('HardSync')
      await caches.delete('GetCards')

      this.setState({
        selectedProductId: newProductId,
        flashcardArray: null,
        flashcards: null,
        // downloadSize & downloadProgress are responsible for the value displayed to the user during GetCards download
        downloadSize: 0,
        downloadProgress: 0
      }, async () => {
        await this.getCards()
        displayNotification(`Product Id changed to ${newProductId}`)
      })
    }
  }

  handleSpacedModeConfirmation = (userDecision) => {
    if (userDecision) {
      let flashcards = {}
      this.state.flashcardArray.forEach((value, index) => {
        flashcards[value.cf.CardGeneratedID] = value.cf
      })
      this.setState({spacedMode: true, displaySpacedModePopup: 0, flashcards}, () => {
        this.state.spacedModeConfirmationCallback(userDecision)
      })
    } else {
      this.setState({spacedMode: false, displaySpacedModePopup: 0}, () => {
        this.state.spacedModeConfirmationCallback(userDecision)
      })
    }
  }

  displaySpacedModePopupHandler = (correct, incorrect, callback, displaySecondSpacedModePopup = true) => {

    let nextReviewDate = new Date(this.state.userSyncData.cd[0].NextReviewDate)

    this.state.userSyncData.cd.forEach((value, index) => {
      let reviewDate = new Date(value.NextReviewDate)
      if (reviewDate < nextReviewDate) {
        nextReviewDate = reviewDate
      }
    })

    const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    let dateString = `${DAYS[nextReviewDate.getDay()]}, ${MONTHS[nextReviewDate.getMonth()]} ${nextReviewDate.getDate()}`

    this.setState({
      displaySpacedModePopup: 1,
      spacedModeCorrect: correct,
      spacedModeIncorrect: incorrect,
      spacedModeNextReviewDate: dateString,
      spacedModeConfirmationCallback: callback,
      displaySecondSpacedModePopup: displaySecondSpacedModePopup ? true : false
    })
  }

  handleSpacedPopupContinue = () => {
    if (this.state.displaySecondSpacedModePopup) {
      this.setState({displaySpacedModePopup: 2, displaySecondSpacedModePopup: true})
    } else {
      this.state.spacedModeConfirmationCallback()
      this.setState({displaySpacedModePopup: 0, displaySecondSpacedModePopup: true})
    }
  }

  enableOtherAnimations = () => {
    this.setState({otherAnimations: !this.state.otherAnimations}, () => {
      this.state.flashcardOtherAnimationsCallback(this.state.otherAnimations)
    })
  }

  enablePageAnimations = () => {
    this.setState({pageAnimations: !this.state.pageAnimations})
  }

  toggleStudyIncomplete = () => {
    this.setState({studyIncomplete: !this.state.studyIncomplete}, () => {
      if (this.state.settingsCallback !== null)
        this.state.settingsCallback()
    })
  }

  updateFlashcardKeyArray = (newKeyArray) => {
    this.setState({flashcardKeyArray: newKeyArray}, () => {
      this.updateStatisticsValues()
    })
  }

  touchEndHandler = (e) => {
    if (!this.state.cardWasClicked) {
      this.setState({cardWasClicked: true}, () => {
        if (this.state.touchEndCallback !== null) {
          this.state.touchEndCallback(e)
        }
        setTimeout(() => {
          this.setState({cardWasClicked: false})
        }, 5)
      })
    }
  }

  displayImageHandler = (url) => {
    this.setState({imageUrl: url, displayImage: true})
  }

  toggleStudyIncorrect = () => {
    this.setState({studyIncorrect: !this.state.studyIncorrect}, () => {
      if (this.state.settingsCallback !== null)
        this.state.settingsCallback()
    })
  }

  render() {
    if (this.state.userData === null) {
      return (
        <div className='appContainer' id='appContainer' style={{height: this.state.windowHeight}}>
          <AnimatePresence exitBeforeEnter>
            <LoginPage
              updateUserData={this.updateUserData}
              key="loginPage"
              themeColor={this.state.themeColor}
              backgroundSync={this.backgroundSync}
              offline={this.state.offline}
            />
          </AnimatePresence>
          <ToastContainer
            toastClassName={'toastContainer'}
          />
        </div>
      )
    } else {
      return (
        <div className='appContainer' id='appContainer' style={{height: this.state.windowHeight}} onTouchEnd={this.touchEndHandler} onMouseUp={this.touchEndHandler}>
          <nav className='navContainer'>
            {/* <Logo /> */}
            <Logo className='Logo' height={25} style={{alignSelf: 'center', marginLeft: '10px'}} stroke='none'/>
            {/* <img className='navLogo' src={Logo} alt="Logo"/> */}
            {/* <a className='navLogo'>*Logo*</a> */}
            <button className='navSettingsButton' onClick={() => this.setState({displayMobileSettings: true})}><FontAwesomeIcon icon={solid('gear')}/></button>
          </nav>
          <div className='sidebarSettingsWrapper'>
            <Settings
              toggleDarkMode={this.toggleDarkMode}
              darkMode={this.state.darkMode}
              mode={this.state.mode}
              enableFlipAnimation={this.enableFlipAnimation}
              flipAnimation={this.state.flipAnimation}
              logoutHandler={this.logoutHandler}
              favoritesOnly={this.state.favoritesOnly}
              enableFavoritesOnly={this.enableFavoritesOnly}
              toggleCloseButton={() => null}
              userData={this.state.userData}
              themeColor={this.state.themeColor}
              changeThemeColor={this.changeThemeColor}
              favoriteTotal={this.state.favoriteTotal}
              disableSpaceBar={this.disableSpaceBar}
              themeChangeOutstanding={this.state.themeChangeOutstanding}
              backgroundSync={this.backgroundSync}
              offline={this.state.offline}
              displayConfirmationHandler={this.displayConfirmationHandler}
              selectedProductId={this.state.selectedProductId}
              resetCardData={this.resetCardData}
              changeShuffle={this.changeShuffle}
              shuffle={this.state.shuffle}
              updateProductId={this.updateProductId}
              flashcardArray={this.state.flashcardArray}
              testingSpacedMode={this.state.testingSpacedMode}
              setSpacedTesting={(newValue) => this.setState({testingSpacedMode: newValue})}
              displayedSyncData={this.state.displayedSyncData}
              spacedMode={this.state.spacedMode}
              isStudying={this.state.isStudying}
              otherAnimations={this.state.otherAnimations}
              pageAnimations={this.state.pageAnimations}
              enableOtherAnimations={this.enableOtherAnimations}
              enablePageAnimations={this.enablePageAnimations}
              toggleStudyIncomplete={this.toggleStudyIncomplete}
              studyIncomplete={this.state.studyIncomplete}
              displayUnauthenticatedHandler={this.displayUnauthenticatedHandler}
              settingsStatisticsData={this.state.settingsStatisticsData}
              updateStatisticsValues={this.updateStatisticsValues}
              displayStatsToggle={this.state.displayStatsToggle}
              updateShowOverallStats={(newValue) => this.setState({showOverallStats: newValue})}
              showOverallStats={this.state.showOverallStats}
              loadingLogout={this.state.loadingLogout}
              toggleStudyIncorrect={this.toggleStudyIncorrect}
              studyIncorrect={this.state.studyIncorrect}
              softSyncHandler={this.softSyncHandler}
              lastSoftSync={this.state.lastSoftSync}
            />
          </div>
          <AnimatePresence exitBeforeEnter>
            {this.state.currentPage === 'Categories' ?
              <CategoriesPage
                startStudyingHandler={this.startStudyingHandler}
                key='CategoriesPage'
                userData={this.state.userData}
                selectedProductId={this.state.selectedProductId}
                displayUnauthenticatedHandler={this.displayUnauthenticatedHandler}
                changePage={this.changePageHandler}
                cardsArray={this.state.flashcardArray}
                getCards={this.getCards}
                themeColor={this.state.themeColor}
                spacedMode={this.state.spacedMode}
                updateSpacedMode={() => this.setState({spacedMode: !this.state.spacedMode})}
                downloadProgress={this.state.downloadProgress}
                downloadSize={this.state.downloadSize}
                otherAnimations={this.state.otherAnimations}
                pageAnimations={this.state.pageAnimations}
              />
            : this.state.currentPage === 'SpacedModeSettings' ?
              <SpacedModeSettings
                changePage={this.changePageHandler}
                userData={this.state.userData}
                updateUserData={this.updateUserData}
                updateLoginCache={this.updateLoginCache}
                displayUnauthenticatedHandler={this.displayUnauthenticatedHandler}
                pageAnimations={this.state.pageAnimations}
                otherAnimations={this.state.otherAnimations}
                themeColor={this.state.themeColor}
              />
            :
              <FlashcardPage
                changePage={this.changePageHandler}
                displayImage={this.displayImageHandler}
                key="FlashcardPage"
                shuffle={this.state.shuffle}
                flipAnimation={this.state.flipAnimation}
                flashcardSettingsCallback={(settingsCallback) => this.setState({settingsCallback})}
                favoritesOnly={this.state.favoritesOnly}
                userData={this.state.userData}
                themeColor={this.state.themeColor}
                updateCardsCorrect={this.updateCardsCorrect}
                spaceBarDisabled={this.state.spaceBarDisabled}
                displayUnauthenticatedHandler={this.displayUnauthenticatedHandler}
                updateSyncData={this.updateSyncData}
                selectedProductId={this.state.selectedProductId}
                verifyLoginStatus={this.verifyLoginStatus}
                favoriteQuestionHandler={this.favoriteQuestionHandler}
                userSyncData={this.state.userSyncData}
                flashcards={this.state.flashcards}
                softSyncHandler={this.softSyncHandler}
                spacedMode={this.state.spacedMode}
                updateSpacedMode={(newMode) => this.setState({spacedMode: newMode})}
                cardsCorrect={this.state.cardsCorrect}
                cardsIncorrect={this.state.cardsIncorrect}
                displayConfirmationHandler={this.displayConfirmationHandler}
                displaySpacedModePopupHandler={this.displaySpacedModePopupHandler}
                testingSpacedMode={this.state.testingSpacedMode}
                enableFavoritesOnly={this.enableFavoritesOnly}
                updateFlashcardKeyArray={this.updateFlashcardKeyArray}
                loadingCards={this.state.loadingCards}
                otherAnimations={this.state.otherAnimations}
                pageAnimations={this.state.pageAnimations}
                studyIncomplete={this.state.studyIncomplete}
                flashcardOtherAnimationsCallback={(callback) => this.setState({flashcardOtherAnimationsCallback: callback})}
                updateStatisticsValues={this.updateStatisticsValues}
                showOverallStats={this.state.showOverallStats}
                settingsStatisticsData={this.state.settingsStatisticsData}
                touchEndCallback={(touchEndCallback) => this.setState({touchEndCallback})}
                studyIncorrect={this.state.studyIncorrect}
              />
            }
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
            {this.state.displayImage &&
              <div className='enlargedImageWrapper'>
                <motion.div className='enlargedImageBackdrop' onClick={() => this.setState({displayImage: false})} initial={this.state.pageAnimations ? backdropTransition.initial: false} animate={this.state.pageAnimations ? backdropTransition.in: false} exit={this.state.pageAnimations ? backdropTransition.out: false} transition={{ duration: backdropTransitionDuration }} key='enlargedImageBackdrop' />
                <motion.div className='enlargedImageContainer' initial={this.state.pageAnimations ? backdropTransition.initial: false} animate={this.state.pageAnimations ? backdropTransition.in: false} exit={this.state.pageAnimations ? backdropTransition.out: false} transition={{ duration: transitionDuration }} key='enlargedImageContainer'>
                {/* <div className='enlargedImageContainer'> */}
                  <QuickPinchZoom
                    onUpdate={this.onImageZoomUpdate}
                  >
                    <img className='enlargedImage' ref={this.imgRef} src={this.state.imageUrl} />
                  </QuickPinchZoom>
                {/* </div> */}
                </motion.div>
                <motion.button className='enlargedImageCloseButton closeButton' onClick={() => this.setState({displayImage: false})} initial={this.state.pageAnimations ? backdropTransition.initial: false} animate={this.state.pageAnimations ? backdropTransition.in: false} exit={this.state.pageAnimations ? backdropTransition.out: false} transition={{ duration: transitionDuration }} />
              </div>
            }
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
            {this.state.displayMobileSettings &&
              <div className='mobileSettingsPageWrapper'>
                <motion.div className='mobileSettingsBackdrop' onClick={() => this.setState({displayMobileSettings: false, showSettingsCloseButton: true})} initial={this.state.pageAnimations ? backdropTransition.initial : false} animate={this.state.pageAnimations ? backdropTransition.in : false} exit={this.state.pageAnimations ? backdropTransition.out : false} transition={{ duration: backdropTransitionDuration }} key='mobileSettingsBackdrop'/>
                <motion.div className='mobileSettingsContainer' initial={this.state.pageAnimations ? settingsDrawerTransition.initial : false} animate={this.state.pageAnimations ? settingsDrawerTransition.in : false} exit={this.state.pageAnimations ? settingsDrawerTransition.out : false} transition={{ duration: transitionDuration }} key='mobileSettingsContainer'>
                  <Settings
                    toggleDarkMode={this.toggleDarkMode}
                    darkMode={this.state.darkMode}
                    mode={this.state.mode}
                    enableFlipAnimation={this.enableFlipAnimation}
                    flipAnimation={this.state.flipAnimation}
                    logoutHandler={this.logoutHandler}
                    favoritesOnly={this.state.favoritesOnly}
                    enableFavoritesOnly={this.enableFavoritesOnly}
                    toggleCloseButton={(buttonState) => this.setState({showSettingsCloseButton: buttonState})}
                    userData={this.state.userData}
                    themeColor={this.state.themeColor}
                    changeThemeColor={this.changeThemeColor}
                    favoriteTotal={this.state.favoriteTotal}
                    disableSpaceBar={this.disableSpaceBar}
                    backgroundSync={this.backgroundSync}
                    offline={this.state.offline}
                    themeChangeOutstanding={this.state.themeChangeOutstanding}
                    displayConfirmationHandler={this.displayConfirmationHandler}
                    selectedProductId={this.state.selectedProductId}
                    resetCardData={this.resetCardData}
                    changeShuffle={this.changeShuffle}
                    shuffle={this.state.shuffle}
                    updateProductId={this.updateProductId}
                    flashcardArray={this.state.flashcardArray}
                    testingSpacedMode={this.state.testingSpacedMode}
                    setSpacedTesting={(newValue) => this.setState({testingSpacedMode: newValue})}
                    displayedSyncData={this.state.displayedSyncData}
                    spacedMode={this.state.spacedMode}
                    isStudying={this.state.isStudying}
                    otherAnimations={this.state.otherAnimations}
                    pageAnimations={this.state.pageAnimations}
                    enableOtherAnimations={this.enableOtherAnimations}
                    enablePageAnimations={this.enablePageAnimations}
                    toggleStudyIncomplete={this.toggleStudyIncomplete}
                    studyIncomplete={this.state.studyIncomplete}
                    displayUnauthenticatedHandler={this.displayUnauthenticatedHandler}
                    settingsStatisticsData={this.state.settingsStatisticsData}
                    updateStatisticsValues={this.updateStatisticsValues}
                    displayStatsToggle={this.state.displayStatsToggle}
                    updateShowOverallStats={(newValue) => this.setState({showOverallStats: newValue})}
                    showOverallStats={this.state.showOverallStats}
                    loadingLogout={this.state.loadingLogout}
                    toggleStudyIncorrect={this.toggleStudyIncorrect}
                    studyIncorrect={this.state.studyIncorrect}
                    softSyncHandler={this.softSyncHandler}
                    lastSoftSync={this.state.lastSoftSync}
                  />
                  <AnimatePresence>
                    {this.state.showSettingsCloseButton && <motion.button className='mobileSettingsCloseButton closeButton' initial={backdropTransition.initial} animate={backdropTransition.in} exit={backdropTransition.out} transition={{ duration: transitionDuration }} onClick={() => this.setState({displayMobileSettings: false, showSettingsCloseButton: true})} />}
                  </AnimatePresence>
                </motion.div>
              </div>
            }
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
          {this.state.displayUnauthenticated &&
            <UnauthenticatedPopup
              closePopup={this.logoutHandler}
              pageAnimations={this.state.pageAnimations}
              licenseExpired={this.state.licenseExpired}
              themeColor={this.state.themeColor}
            />
          }
          </AnimatePresence>
          <ToastContainer
            toastClassName={'toastContainer'}
          />
          <AnimatePresence exitBeforeEnter>
            {this.state.displayConfirmationPopup &&
              <ConfirmationPopup
                confirm={this.handleConfirmationPopupClick}
                header={this.state.confirmationData.header}
                body={this.state.confirmationData.body}
                primaryButtonText={this.state.confirmationData.primaryButtonText ? this.state.confirmationData.primaryButtonText : false}
                secondaryButtonText={this.state.confirmationData.secondaryButtonText ? this.state.confirmationData.secondaryButtonText : false}
                key='confirmationPopup'
                enableSecondaryButton={this.state.confirmationData.enableSecondaryButton}
                pageAnimations={this.state.pageAnimations}
              />
            }
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
            {this.state.displaySpacedModePopup === 1 ?
              <SpacedModePopup
                correct={this.state.spacedModeCorrect}
                missed={this.state.spacedModeIncorrect}
                nextReviewDate={this.state.spacedModeNextReviewDate}
                continue={this.handleSpacedPopupContinue}
                pageAnimations={this.state.pageAnimations}
              />
            : this.state.displaySpacedModePopup === 2 ?
              <ConfirmationPopup
                header={'Continue In Spaced Mode?'}
                primaryButtonText={'Spaced'}
                secondaryButtonText={'Standard'}
                confirm={this.handleSpacedModeConfirmation}
                body={'Would you like to continue studying in spaced mode? This will add new cards to your spaced routine.'}
                key='spacedModeConfirmationPopup'
                enableSecondaryButton={true}
                pageAnimations={this.state.pageAnimations}
              />
            :
              null
            }
          </AnimatePresence>
        </div>
      )
    }
  }
}

export default App;
